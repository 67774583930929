import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import { StaticImage } from "gatsby-plugin-image"

export default function About({ data }) {
  const { t } = useTranslation()
  return (
    <Layout subTitle={t("About")}>
      <section className="section is-medium has-text-centered">
        <h2 className="title is-2 is-spaced">
          <Trans>
            The Freundeskreis Nürnberg-Glasgow association was founded in
            December 2002
          </Trans>
        </h2>
        <h3 className="subtitle is-4 is-spaced">
          <Trans>about_text</Trans>
        </h3>
      </section>
      <section className="section">
        <div className="columns is-desktop">
          <div className="column">
            <StaticImage
              src="../images/glasgow-goma.png"
              alt="Glasgow GoMA"
              placeholder="blurred"
              width="400"
            />
          </div>
          <div className="column">
            <div>
              <h3 className="title is-3 pb-2">
                <Trans>Our aim</Trans>
              </h3>
            </div>
            <div>
              <Trans>aim_intro</Trans>
            </div>
            <ul style={{ listStyle: "unset", margin: "1em", padding: "1em" }}>
              <li>
                <Trans>aim_1</Trans>
              </li>
              <li>
                <Trans>aim_2</Trans>
              </li>
              <li>
                <Trans>aim_3</Trans>
              </li>
              <li>
                <Trans>aim_4</Trans>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="columns is-desktop">
          <div className="column">
            <div>
              <h3 className="title is-3 pb-2">
                <Trans>Members</Trans>
              </h3>
            </div>
            <div>
              <Trans>member_text</Trans>
            </div>
            <ul style={{ listStyle: "unset", margin: "1em", padding: "1em" }}>
              <li>
                <Trans>annual_contribution</Trans>
              </li>
              <li>
                <Trans>discounted_contribution</Trans>
              </li>
            </ul>
            <div className="block">
              <Trans>interested_text</Trans>
            </div>
          </div>
          <div className="column">
            <StaticImage
              src="../images/nuremberg-river.png"
              alt="Nürnberg River"
              placeholder="blurred"
              width="400"
            />
          </div>
        </div>
      </section>
      <section className="section is-medium has-text-centered">
        <h3 className="title is-3 is-spaced">
          <Trans>Board of Directors</Trans>
        </h3>
        <h4 className="subtitle is-5 is-spaced">
          <Trans>Dedicated to quality and your success</Trans>
        </h4>
        <div className="columns is-desktop">
          <div className="column">
            <img
              style={{ borderRadius: "50%" }}
              src="https://dummyimage.com/150x150/ced4da/6c757d"
              alt="..."
            ></img>
            <p className="title is-size-5">Malte Denker</p>
            <p className="subtitle is-size-6">
              <Trans>Chair</Trans>
            </p>
          </div>
          <div className="column">
            <img
              style={{ borderRadius: "50%" }}
              src="https://dummyimage.com/150x150/ced4da/6c757d"
              alt="..."
            ></img>
            <p className="title is-size-5">Dr. Hans-Dieter Metzger</p>
            <p className="subtitle is-size-6">
              <Trans>Chair</Trans>
            </p>
          </div>
          <div className="column">
            <img
              style={{ borderRadius: "50%" }}
              src="https://dummyimage.com/150x150/ced4da/6c757d"
              alt="..."
            ></img>
            <p className="title is-size-5">Jochen Sand</p>
            <p className="subtitle is-size-6">
              <Trans>Committee Members</Trans>
            </p>
          </div>
          <div className="column">
            <img
              style={{ borderRadius: "50%" }}
              src="https://dummyimage.com/150x150/ced4da/6c757d"
              alt="..."
            ></img>
            <p className="title is-size-5">Marion Schück</p>
            <p className="subtitle is-size-6">
              <Trans>Treasurer</Trans>
            </p>
          </div>
          <div className="column">
            <img
              style={{ borderRadius: "50%" }}
              src="https://dummyimage.com/150x150/ced4da/6c757d"
              alt="..."
            ></img>
            <p className="title is-size-5">Barbara Denker</p>
            <p className="subtitle is-size-6">
              <Trans>Secretary</Trans>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
